import React from 'react'
import { graphql } from 'gatsby'
import { Helmet, useI18next, Link } from 'gatsby-plugin-react-i18next'
import './index.css'

const IndexPage = () => {
  const { t, language, originalPath } = useI18next()
  const switchLanguage = language === 'en' ? 'zh' : 'en'

  return (
    <main className="bg-black">
      <Helmet>
        <title>Loss DAO</title>
      </Helmet>

      <video
        muted
        loop
        autoPlay
        className="fixed top-0 left-0 w-screen h-screen object-cover"
      >
        <source src="/bg.mp4" type="video/mp4" />
      </video>
      <div className="fixed top-0 left-0 right-0 bottom-0 bg-black/75"></div>

      <div className="relative w-screen h-screen flex items-center justify-center flex-col">
        <img
          className="lg:w-60 lg:h-60 w-48 h-48 object-cover rounded-3xl"
          src="/icon.png"
          alt="logo"
        />
        <h1 className="font-extrabold text-6xl text-white text-center mt-12 lg:text-8xl">
          <span className="text-primary">Loss</span> DAO
        </h1>

        <a
          className="mt-12 inline-flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-white bg-primary"
          href="#donate"
        >
          {t('Donate')}
        </a>

        <a
          className="mt-12 text-xl text-primary underline font-bold"
          href="https://discord.gg/hYkHZ5Dh"
        >
          {t('Join')} Discord
        </a>

        <Link to={originalPath} language={switchLanguage}>
          <button className="text-white mt-6">
            {switchLanguage.toUpperCase()}
          </button>
        </Link>

        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-8 w-8 text-white/40 absolute bottom-8"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            d="M19 13l-7 7-7-7m14-8l-7 7-7-7"
          />
        </svg>
      </div>

      <div className="relative flex items-center flex-col min-h-screen mb-12">
        <div className="block" id="donate">
          <h2 className="block-title">{t('D0')}</h2>
          <div className='flex justify-center mt-12'>
            <a href='https://juicebox.money/#/p/lossdao' target='_blank' className='flex items-center justify-center border-gray-300 border pl-4 pr-4 cursor-pointer text-white bg-custom button w-3/4 text-center h-12'>{t('juicebox')}</a>
            </div>
          <p className="mt-12">{t('D4')}</p>
          <p className="mt-12">
            {t('D1')}
            <a href='#benifit' className='underline'>
              {t('D2')}
            </a>
            {t('D3')}
          </p>
        </div>

        <div className="block">
          <h2 className="block-title">{t('H1')}</h2>
          <p className="mt-12">{t('P1')}</p>
          <p className="mt-12">{t('P2')}</p>
        </div>

        <div className="block" id='benifit'>
          <h2 className="block-title">{t('H2')}</h2>
          <div className="mt-12">{t('P3')}</div>
          <p className="mt-12">{t('P4')}</p>
        </div>
        {/* <div className="block">
          <h2 className="block-title">{t('H3')}</h2>
          <div className="mt-12">{t('P5')}</div>
        </div> */}
      </div>
    </main>
  )
}

export default IndexPage

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
